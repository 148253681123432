/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Chkk Admin API v1.0
 * Chkk Admin API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

export type PlanUsageLimitType =
  (typeof PlanUsageLimitType)[keyof typeof PlanUsageLimitType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanUsageLimitType = {
  Hard: 'Hard',
  Soft: 'Soft',
} as const;
