import {
  PropertyFilterOperator,
  PropertyFilterOption,
  PropertyFilterQuery,
  useCollection,
} from '@cloudscape-design/collection-hooks';
import {
  Box,
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
  TableProps,
} from '@cloudscape-design/components';
import { NonCancelableEventHandler } from '@cloudscape-design/components/internal/events';
import { propertyFilterI18nStrings } from 'i18n/property-filter';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface FilterableTableProps<T> {
  data?: readonly T[];
  selectionMode: 'none' | 'single';
  variant?: TableProps.Variant;

  columns: {
    id: string;
    label: string;
    cell: (val: T) => React.ReactNode;

    defaultVisible: boolean;
    alwaysVisible?: boolean;

    sortingField?: string;

    filterOperators?: readonly PropertyFilterOperator[];
    filterOptions?: string[];
  }[];
  selectionTrackBy: (val: T) => string;
  defaultFilterQuery?: (
    searchParams: URLSearchParams,
  ) => Promise<PropertyFilterQuery>;

  header?: React.ReactNode;
  onSelectionChange?: NonCancelableEventHandler<
    TableProps.SelectionChangeDetail<T>
  >;
}

export function FilterableTable<T>(
  props: FilterableTableProps<T>,
): JSX.Element {
  const [searchParams] = useSearchParams();

  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      pageSize: 30,
      contentDisplay: props.columns.map((f) => ({
        id: f.id,
        visible: f.defaultVisible,
      })),
      wrapLines: false,
      stripedRows: false,
    });

  const columnDefinitions: TableProps.ColumnDefinition<T>[] = props.columns.map(
    (f) => ({
      id: f.id,
      header: f.label,
      cell: f.cell,
      sortingField: f.sortingField,
    }),
  );

  const {
    actions,
    items,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(props.data || [], {
    propertyFiltering: {
      filteringProperties: props.columns
        .filter((f) => (f.filterOperators || []).length > 0)
        .map((f) => ({
          key: f.id,
          operators: f.filterOperators,
          propertyLabel: f.label,
          groupValuesLabel: `${f.label} values`,
        })),
      empty: (
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: 's' }} variant="p" color="inherit">
            No resources to display.
          </Box>
        </Box>
      ),
      noMatch: (
        <Box textAlign="center" color="inherit">
          <b>No matching resources </b>
          <Box padding={{ bottom: 's' }} variant="p" color="inherit">
            No matching resources to display.
          </Box>
        </Box>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
    selection: {
      keepSelection: true,
      trackBy: props.selectionTrackBy,
    },
  });

  useEffect(() => {
    const processDefault = async () => {
      if (props.defaultFilterQuery) {
        const defaultQuery = await props.defaultFilterQuery(searchParams);
        if (defaultQuery) {
          actions.setPropertyFiltering(defaultQuery);
        }
      }
    };

    void processDefault();
  }, [searchParams]);

  return (
    <Table
      {...collectionProps}
      variant={props.variant || 'embedded'}
      columnDisplay={preferences.contentDisplay}
      wrapLines={preferences.wrapLines}
      stripedRows={preferences.stripedRows}
      resizableColumns
      loading={props.data === undefined}
      loadingText="Loading resources"
      items={items}
      selectionType={props.selectionMode === 'none' ? undefined : 'single'}
      columnDefinitions={columnDefinitions}
      onSelectionChange={(event) => {
        if (collectionProps.onSelectionChange) {
          collectionProps.onSelectionChange(event);
        }
        if (props.onSelectionChange) {
          props.onSelectionChange(event);
        }
      }}
      header={props.header}
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          i18nStrings={propertyFilterI18nStrings}
          expandToViewport={true}
          filteringOptions={props.columns
            .map<PropertyFilterOption[]>((f) =>
              (f.filterOptions || [])
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                .map((fo) => ({
                  propertyKey: f.id,
                  value: fo,
                })),
            )
            .reduce((acc, val) => acc.concat(...val), [])}
        />
      }
      pagination={<Pagination {...paginationProps} />}
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: 'Page size',
            options: [
              { value: 10, label: '10' },
              { value: 30, label: '30' },
              { value: 50, label: '50' },
              { value: 100, label: '100' },
            ],
          }}
          wrapLinesPreference={{
            label: 'Wrap lines',
            description: 'Select to see all the text and wrap the lines',
          }}
          stripedRowsPreference={{
            label: 'Striped rows',
            description: 'Select to add alternating shaded rows',
          }}
          contentDisplayPreference={{
            title: 'Column preferences',
            description: 'Customize the columns visibility and order.',
            options: props.columns.map((f) => ({
              id: f.id,
              label: f.label,
              alwaysVisible: f.alwaysVisible,
            })),
          }}
        />
      }
    />
  );
}
