import {
  createSubscription,
  updateSubscription,
  useGetSubscription,
  useListAddons,
  useListSubscriptions,
} from 'api/admin';
import { FilterableTable } from 'components/tables/filterable-table';
import { format } from 'date-fns';
import {
  Badge,
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  DatePicker,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import CopyText from 'components/copy-text';
import { ValueWithLabel } from 'components/value-with-label';
import { ActionButton } from 'pages/upgrade_plans/helpers';
import { useEffect, useState } from 'react';
import {
  Addon,
  AddonEntitlement,
  AdminSubscription,
  PlanUsageLimitType,
  ResolvedEntitlement,
  SubscriptionPlan,
  SubscriptionType,
} from 'api/admin-models';
import { useListAddonsInfinite } from 'api/admin-infinite';

export function OrganizationSubscriptions(props: {
  orgId: string;
  setError?: (error: string) => void;
}): JSX.Element {
  const [subscriptionCreatorVisible, setSubscriptionCreatorVisible] =
    useState(false);
  const { data, mutate } = useListSubscriptions(props.orgId);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    string | undefined
  >(undefined);

  const [subscriptionToEdit, setSubscriptionToEdit] = useState<
    AdminSubscription | undefined
  >();
  const [
    editSubscriptionNameDialogVisible,
    setEditSubscriptionNameDialogVisible,
  ] = useState(false);

  return (
    <Container>
      <SpaceBetween direction="vertical" size="m">
        <FilterableTable
          selectionMode="single"
          data={data?.data}
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <AccountSubscriptionCreator
                    accountId={props.orgId}
                    onConfirm={() => setSubscriptionCreatorVisible(false)}
                    onDismiss={() => setSubscriptionCreatorVisible(false)}
                    visible={subscriptionCreatorVisible}
                    setError={props.setError}
                  />
                  <ActionButton
                    key="create-subscription"
                    onClick={() => setSubscriptionCreatorVisible(true)}
                  >
                    Create Subscription
                  </ActionButton>
                  <Button
                    iconName="refresh"
                    variant="icon"
                    onClick={() => void mutate()}
                  />
                </SpaceBetween>
              }
            >
              Subscriptions
            </Header>
          }
          columns={[
            {
              id: 'Id',
              label: 'Subscription ID',
              cell: (item) => item.subscription_id,

              defaultVisible: true,
              alwaysVisible: true,

              sortingField: 'id',
            },
            {
              id: 'Name',
              label: 'Name',
              cell: (item) => item.name || '-',

              defaultVisible: true,

              sortingField: 'name',
              filterOperators: ['=', '!=', ':', '!:'],
            },
            {
              id: 'Plan',
              label: 'Plan',
              cell: (item) => item.plan,

              defaultVisible: true,

              sortingField: 'plan',
              filterOperators: ['=', '!=', ':', '!:'],
            },
            {
              id: 'Type',
              label: 'Type',
              cell: (item) => item.type,

              defaultVisible: true,

              sortingField: 'type',
              filterOperators: ['=', '!=', ':', '!:'],
            },
            {
              id: 'Created',
              label: 'Created',
              cell: (item) => format(item.created * 1000, 'Pp'),

              defaultVisible: true,

              sortingField: 'created',
              filterOperators: ['=', '!=', '<', '>', '<=', '>='],
            },
            {
              id: 'Started',
              label: 'Started',
              cell: (item) => {
                if (!item.started) {
                  return '-';
                }

                return format(item.started * 1000, 'Pp');
              },

              defaultVisible: true,

              sortingField: 'started',
              filterOperators: ['=', '!=', '<', '>', '<=', '>='],
            },
            {
              id: 'Expiry',
              label: 'Expiry',
              cell: (item) => {
                if (!item.expiry) {
                  return '-';
                }

                return format(item.expiry * 1000, 'Pp');
              },

              defaultVisible: true,

              sortingField: 'expiry',
              filterOperators: ['=', '!=', '<', '>', '<=', '>='],
            },
            {
              id: 'Revocation',
              label: 'Revocation',
              cell: (item) => {
                if (!item.revocation) {
                  return '-';
                }

                return format(item.revocation * 1000, 'Pp');
              },

              defaultVisible: true,

              sortingField: 'revocation',
              filterOperators: ['=', '!=', '<', '>', '<=', '>='],
            },
            {
              id: 'actions',
              label: 'Actions',
              cell: (item) => (
                <Button
                  iconName="edit"
                  variant="icon"
                  ariaLabel="Edit Subscription Name"
                  onClick={() => {
                    setEditSubscriptionNameDialogVisible(true);
                    setSubscriptionToEdit(item);
                  }}
                />
              ),
              defaultVisible: true,
            },
          ]}
          selectionTrackBy={(item) => item.subscription_id}
          onSelectionChange={(event) =>
            setSelectedSubscriptionId(
              event.detail.selectedItems[0].subscription_id,
            )
          }
        />
        {editSubscriptionNameDialogVisible && (
          <EditSubscriptionNameDialog
            visible={editSubscriptionNameDialogVisible}
            onDismiss={() => setEditSubscriptionNameDialogVisible(false)}
            subscription={subscriptionToEdit}
            accountId={props.orgId}
            mutate={mutate}
          />
        )}
        {selectedSubscriptionId && (
          <OrganizationSubscriptionDetail
            orgId={props.orgId}
            subscriptionId={selectedSubscriptionId}
            setError={props.setError}
          />
        )}
      </SpaceBetween>
    </Container>
  );
}
const EditSubscriptionNameDialog = (props: {
  visible: boolean;
  onDismiss: () => void;
  subscription: AdminSubscription | undefined;
  accountId: string;
  mutate: () => Promise<void>;
}) => {
  const [newName, setNewName] = useState(props.subscription?.name || '');
  const [processing, setProcessing] = useState(false);

  const onConfirm = async (newName: string) => {
    setProcessing(true);
    try {
      await updateSubscription(
        props.accountId,
        props.subscription?.subscription_id || '',
        {
          name: newName,
        },
      );
      await props.mutate();
    } catch (err) {
      console.error(err);
    } finally {
      setProcessing(false);
      props.onDismiss();
    }
  };

  return (
    <Modal
      visible={props.visible}
      onDismiss={props.onDismiss}
      header="Edit Subscription Name"
      footer={
        <SpaceBetween direction="horizontal" size="s">
          <Button variant="normal" onClick={props.onDismiss}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => onConfirm(newName)}
            loading={processing}
          >
            Save
          </Button>
        </SpaceBetween>
      }
    >
      <FormField label="Subscription Name">
        <Input
          value={newName}
          onChange={({ detail }) => setNewName(detail.value)}
        />
      </FormField>
    </Modal>
  );
};

function OrganizationSubscriptionDetail(props: {
  orgId: string;
  subscriptionId: string;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data: subscription } = useGetSubscription(
    props.orgId,
    props.subscriptionId,
  );
  const [showEndDatesSelector, setShowEndDatesSelector] = useState(false);
  const [nodeCountAdjustor, setNodeCountAdjustor] = useState(false);
  const [upgradePlanCountAdjustor, setUpgradePlanCountAdjustor] =
    useState(false);
  const [upgradeTemplateCountAdjustor, setUpgradeTemplateCountAdjustor] =
    useState(false);
  const [clusterCountAdjustor, setClusterCountAdjustor] = useState(false);

  return (
    <>
      <AccountSubscriptionEndDatesSelector
        accountId={props.orgId}
        subscriptionId={props.subscriptionId}
        onConfirm={() => setShowEndDatesSelector(false)}
        onDismiss={() => setShowEndDatesSelector(false)}
        visible={showEndDatesSelector}
        setError={props.setError}
      />
      {/* TODO: dedup adjustors */}
      <AccountSubscriptionNodeCountAdjustor
        accountId={props.orgId}
        subscriptionId={props.subscriptionId}
        onConfirm={() => setNodeCountAdjustor(false)}
        onDismiss={() => setNodeCountAdjustor(false)}
        visible={nodeCountAdjustor}
        setError={props.setError}
      />
      <AccountSubscriptionUpgradePlanCountAdjustor
        accountId={props.orgId}
        subscriptionId={props.subscriptionId}
        onConfirm={() => setUpgradePlanCountAdjustor(false)}
        onDismiss={() => setUpgradePlanCountAdjustor(false)}
        visible={upgradePlanCountAdjustor}
        setError={props.setError}
      />
      <AccountSubscriptionUpgradeTemplateCountAdjustor
        accountId={props.orgId}
        subscriptionId={props.subscriptionId}
        onConfirm={() => setUpgradeTemplateCountAdjustor(false)}
        onDismiss={() => setUpgradeTemplateCountAdjustor(false)}
        visible={upgradeTemplateCountAdjustor}
        setError={props.setError}
      />
      <AccountSubscriptionClusterCountAdjustor
        accountId={props.orgId}
        subscriptionId={props.subscriptionId}
        onConfirm={() => setClusterCountAdjustor(false)}
        onDismiss={() => setClusterCountAdjustor(false)}
        visible={clusterCountAdjustor}
        setError={props.setError}
      />

      <SpaceBetween size="l">
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <ActionButton
                key="update-expiry"
                onClick={() => setShowEndDatesSelector(true)}
              >
                Update Subscription Dates
              </ActionButton>
            </SpaceBetween>
          }
        >
          Subscription Details
        </Header>
        <ColumnLayout columns={3} variant="text-grid">
          <ValueWithLabel label="Account ID">
            <CopyText
              copyText={subscription?.org_id || ''}
              copyButtonLabel="Copy Account ID"
              successText="Account ID copied"
              errorText="Account ID failed to copy"
            />
          </ValueWithLabel>
          <ValueWithLabel label="ID">
            <CopyText
              copyText={subscription?.subscription_id || ''}
              copyButtonLabel="Copy Subscription ID"
              successText="Subscription ID copied"
              errorText="Subscription ID failed to copy"
            />
          </ValueWithLabel>
          <ValueWithLabel label="Name">
            {subscription?.name || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Plan">
            {subscription?.plan || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Type">
            {subscription?.type || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Created">
            {subscription?.created && format(subscription.created * 1000, 'Pp')}
          </ValueWithLabel>
          <ValueWithLabel label="Started">
            {subscription?.started
              ? format(subscription.started * 1000, 'Pp')
              : '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Expiry">
            {subscription?.expiry
              ? format(subscription.expiry * 1000, 'Pp')
              : '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Revocation">
            {subscription?.revocation
              ? format(subscription.revocation * 1000, 'Pp')
              : '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Features">
            <SpaceBetween direction="horizontal" size="xs">
              {(subscription?.entitlement?.features || []).includes(
                'request_addon',
              ) && <Badge>Request Addon</Badge>}
              {(subscription?.entitlement?.features || []).includes(
                'request_cloud',
              ) && <Badge>Request Cloud</Badge>}
            </SpaceBetween>
          </ValueWithLabel>
        </ColumnLayout>
        <Header variant="h2">Entitlement</Header>
        <ColumnLayout columns={3} variant="text-grid">
          <Container
            header={
              <Header
                variant="h3"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <ActionButton
                      key="update-node-count"
                      onClick={() => setNodeCountAdjustor(true)}
                    >
                      Adjust
                    </ActionButton>
                  </SpaceBetween>
                }
              >
                Node Usage
              </Header>
            }
          >
            <ValueWithLabel label="Count">
              {subscription?.entitlement?.node_count.limit}
            </ValueWithLabel>
            <ValueWithLabel label="Limit Type">
              {subscription?.entitlement?.node_count.type}
            </ValueWithLabel>
          </Container>
          <Container
            header={
              <Header
                variant="h3"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <ActionButton
                      key="update-upgrade-plan-count"
                      onClick={() => setUpgradePlanCountAdjustor(true)}
                    >
                      Adjust
                    </ActionButton>
                  </SpaceBetween>
                }
              >
                Upgrade Plan Usage
              </Header>
            }
          >
            <ValueWithLabel label="Count">
              {subscription?.entitlement?.upgrade_plan_count !== undefined
                ? subscription.entitlement.upgrade_plan_count.limit
                : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Limit Type">
              {subscription?.entitlement?.upgrade_plan_count !== undefined
                ? subscription.entitlement.upgrade_plan_count.type
                : '-'}
            </ValueWithLabel>
          </Container>
          <Container
            header={
              <Header
                variant="h3"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <ActionButton
                      key="update-upgrade-template-count"
                      onClick={() => setUpgradeTemplateCountAdjustor(true)}
                    >
                      Adjust
                    </ActionButton>
                  </SpaceBetween>
                }
              >
                Upgrade Template Usage
              </Header>
            }
          >
            <ValueWithLabel label="Count">
              {subscription?.entitlement?.upgrade_template_count !== undefined
                ? subscription.entitlement.upgrade_template_count.limit
                : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Limit Type">
              {subscription?.entitlement?.upgrade_template_count !== undefined
                ? subscription.entitlement.upgrade_template_count.type
                : '-'}
            </ValueWithLabel>
          </Container>
          <Container
            header={
              <Header
                variant="h3"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <ActionButton
                      key="update-cluster-count"
                      onClick={() => setClusterCountAdjustor(true)}
                    >
                      Adjust
                    </ActionButton>
                  </SpaceBetween>
                }
              >
                Cluster Usage
              </Header>
            }
          >
            <ValueWithLabel label="Count">
              {subscription?.entitlement?.cluster_count !== undefined
                ? subscription.entitlement.cluster_count.limit
                : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Limit Type">
              {subscription?.entitlement?.cluster_count !== undefined
                ? subscription.entitlement.cluster_count.type
                : '-'}
            </ValueWithLabel>
          </Container>
        </ColumnLayout>
        <AccountSubscriptionAddonEntitlementAdjustor
          accountId={props.orgId}
          subscriptionId={props.subscriptionId}
          visible={true}
        />
      </SpaceBetween>
    </>
  );
}

function AccountSubscriptionEndDatesSelector(props: {
  accountId: string;
  subscriptionId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data: subscription, mutate } = useGetSubscription(
    props.accountId,
    props.subscriptionId,
  );
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [expiryDate, setExpiryDate] = useState<Date | undefined>(undefined);
  const [revocationDate, setRevocationDate] = useState<Date | undefined>(
    undefined,
  );

  useEffect(() => {
    if (subscription?.expiry) {
      const date = new Date(subscription.expiry * 1000);
      setExpiryDate(date);
    }
    if (subscription?.revocation) {
      const date = new Date(subscription.revocation * 1000);
      setRevocationDate(date);
    }
    if (subscription?.started) {
      const date = new Date(subscription.started * 1000);
      setStartDate(date);
    }
  }, [subscription, props.visible]);

  const [processing, setProcessing] = useState(false);

  const resetValues = () => {
    setStartDate(undefined);
    setExpiryDate(undefined);
    setRevocationDate(undefined);
  };

  const onConfirmClick = async () => {
    setProcessing(true);
    try {
      await updateSubscription(props.accountId, props.subscriptionId, {
        expiry: expiryDate ? Math.ceil(expiryDate.getTime() / 1000) : 0,
        revocation: revocationDate
          ? Math.ceil(revocationDate.getTime() / 1000)
          : 0,
        started: startDate ? Math.ceil(startDate.getTime() / 1000) : 0,
      });
      await mutate();
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      props.onDismiss();
    }
    setProcessing(false);
    resetValues();
  };

  return (
    <Modal
      header={`Update Subscription Dates`}
      onDismiss={() => {
        props.onDismiss();
        resetValues();
      }}
      visible={props.visible}
    >
      <SpaceBetween direction="vertical" size="m">
        <ColumnLayout>
          <Container header={<Header variant="h3">Start</Header>}>
            <ValueWithLabel label="Current Start Date">
              {subscription?.started
                ? format(subscription.started * 1000, 'P')
                : '-'}
            </ValueWithLabel>

            <FormField label="Date" constraintText="Use YYYY/MM/DD format.">
              <DatePicker
                value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
                onChange={(event) => {
                  if (event.detail.value === '') {
                    setStartDate(undefined);
                  } else {
                    setStartDate(new Date(event.detail.value));
                  }
                }}
                placeholder={'None'}
              />
            </FormField>
          </Container>
          <Container header={<Header variant="h3">Expiry</Header>}>
            <ValueWithLabel label="Current Expiry">
              {subscription?.expiry
                ? format(subscription.expiry * 1000, 'P')
                : '-'}
            </ValueWithLabel>

            <FormField label="Date" constraintText="Use YYYY/MM/DD format.">
              <DatePicker
                value={expiryDate ? format(expiryDate, 'yyyy-MM-dd') : ''}
                onChange={(event) => {
                  if (event.detail.value === '') {
                    setExpiryDate(undefined);
                  } else {
                    setExpiryDate(new Date(event.detail.value));
                  }
                }}
                placeholder={'None'}
              />
            </FormField>
          </Container>
          <Container header={<Header variant="h3">Revocation</Header>}>
            <ValueWithLabel label="Current Revocation">
              {subscription?.revocation
                ? format(subscription.revocation * 1000, 'P')
                : '-'}
            </ValueWithLabel>
            <FormField label="Date" constraintText="Use YYYY/MM/DD format.">
              <DatePicker
                value={
                  revocationDate ? format(revocationDate, 'yyyy-MM-dd') : ''
                }
                onChange={(event) => {
                  if (event.detail.value === '') {
                    setRevocationDate(undefined);
                  } else {
                    setRevocationDate(new Date(event.detail.value));
                  }
                }}
                placeholder={'None'}
              />
            </FormField>
          </Container>
        </ColumnLayout>
        <SpaceBetween direction="horizontal" size="xs">
          <ActionButton
            key="confirm"
            onConfirm={onConfirmClick}
            disabled={processing}
          >
            Confirm
          </ActionButton>
          <ActionButton
            key="cancel"
            onClick={() => {
              props.onDismiss();
              resetValues();
            }}
          >
            Cancel
          </ActionButton>
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
}

function AccountSubscriptionNodeCountAdjustor(props: {
  accountId: string;
  subscriptionId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data, mutate } = useListSubscriptions(props.accountId);
  const subscription = data?.data.find(
    (s) => s.subscription_id === props.subscriptionId,
  );

  const [nodeCountLimit, setNodeCountLimit] = useState(
    subscription?.entitlement.node_count?.limit || 0,
  );
  const [nodeCountType, setNodeCountType] = useState<{
    label?: string;
    value?: string;
  }>({
    label: subscription?.entitlement.node_count?.type || 'Hard',
    value: subscription?.entitlement.node_count?.type || 'Hard',
  });

  useEffect(() => {
    if (subscription?.entitlement?.node_count) {
      setNodeCountLimit(subscription.entitlement.node_count.limit);
      setNodeCountType({
        label: subscription.entitlement.node_count.type,
        value: subscription.entitlement.node_count.type,
      });
    } else {
      setNodeCountLimit(0);
      setNodeCountType({
        label: 'Hard',
        value: 'Hard',
      });
    }
  }, [subscription, props.visible]);

  const onConfirmClick = async () => {
    try {
      await updateSubscription(props.accountId, props.subscriptionId, {
        entitlement: {
          node_count: {
            limit: nodeCountLimit,
            type: nodeCountType.value as PlanUsageLimitType,
          },
        },
      });
      await mutate();
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      props.onDismiss();
    }
  };

  return (
    <ProcessingDialogModal
      header={`Update Node Count`}
      onConfirm={onConfirmClick}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <Container>
        <ResolvedEntitlementAdjustor
          limit={nodeCountLimit.toString()}
          limitOnChange={setNodeCountLimit}
          type={nodeCountType}
          typeOnChange={setNodeCountType}
        />
      </Container>
    </ProcessingDialogModal>
  );
}

function ProcessingDialogModal(props: {
  header: string;
  onConfirm: () => Promise<void>;
  onDismiss: () => void;
  visible: boolean;
  children: React.ReactNode;
}): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onConfirmClick = async () => {
    setProcessing(true);
    try {
      await props.onConfirm();
    } catch (err: unknown) {
      console.error(err);
    }
    setProcessing(false);
  };

  return (
    <Modal
      header={props.header}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <SpaceBetween direction="vertical" size="m">
        {props.children}
        <SpaceBetween direction="horizontal" size="xs">
          <ActionButton
            key="confirm"
            onConfirm={onConfirmClick}
            disabled={processing}
          >
            Confirm
          </ActionButton>
          <ActionButton key="cancel" onClick={props.onDismiss}>
            Cancel
          </ActionButton>
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
}

function AccountSubscriptionUpgradePlanCountAdjustor(props: {
  accountId: string;
  subscriptionId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data, mutate } = useListSubscriptions(props.accountId);
  const subscription = data?.data.find(
    (s) => s.subscription_id === props.subscriptionId,
  );

  const [upgradePlanCountLimit, setUpgradePlanCountLimit] = useState(
    subscription?.entitlement.upgrade_plan_count?.limit || 0,
  );
  const [upgradePlanCountType, setUpgradePlanCountType] = useState<{
    label?: string;
    value?: string;
  }>({
    label: subscription?.entitlement.upgrade_plan_count?.type || 'Hard',
    value: subscription?.entitlement.upgrade_plan_count?.type || 'Hard',
  });

  useEffect(() => {
    if (subscription?.entitlement?.upgrade_plan_count) {
      setUpgradePlanCountLimit(
        subscription.entitlement.upgrade_plan_count?.limit,
      );
      setUpgradePlanCountType({
        label: subscription.entitlement.upgrade_plan_count?.type,
        value: subscription.entitlement.upgrade_plan_count?.type,
      });
    } else {
      setUpgradePlanCountLimit(0);
      setUpgradePlanCountType({
        label: 'Hard',
        value: 'Hard',
      });
    }
  }, [subscription, props.visible]);

  const onConfirmClick = async () => {
    try {
      await updateSubscription(props.accountId, props.subscriptionId, {
        entitlement: {
          // FIXME: this is a hack to get around the fact that the API
          // requires node_count field to be present when updating
          node_count: subscription?.entitlement.node_count || {
            limit: 0,
            type: 'Hard',
          },
          upgrade_plan_count: {
            limit: upgradePlanCountLimit,
            type: upgradePlanCountType.value as PlanUsageLimitType,
          },
        },
      });
      await mutate();
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      props.onDismiss();
    }
  };

  return (
    <ProcessingDialogModal
      header={`Update Upgrade Plan Count`}
      onConfirm={onConfirmClick}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <Container>
        <ResolvedEntitlementAdjustor
          limit={upgradePlanCountLimit.toString()}
          limitOnChange={setUpgradePlanCountLimit}
          type={upgradePlanCountType}
          typeOnChange={setUpgradePlanCountType}
        />
      </Container>
    </ProcessingDialogModal>
  );
}

function AccountSubscriptionUpgradeTemplateCountAdjustor(props: {
  accountId: string;
  subscriptionId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data, mutate } = useListSubscriptions(props.accountId);
  const subscription = data?.data.find(
    (s) => s.subscription_id === props.subscriptionId,
  );

  const [upgradeTemplateCountLimit, setUpgradeTemplateCountLimit] = useState(
    subscription?.entitlement.upgrade_template_count?.limit || 0,
  );
  const [upgradeTemplateCountType, setUpgradeTemplateCountType] = useState<{
    label?: string;
    value?: string;
  }>({
    label: subscription?.entitlement.upgrade_template_count?.type || 'Hard',
    value: subscription?.entitlement.upgrade_template_count?.type || 'Hard',
  });

  useEffect(() => {
    if (subscription?.entitlement?.upgrade_template_count) {
      setUpgradeTemplateCountLimit(
        subscription.entitlement.upgrade_template_count?.limit,
      );
      setUpgradeTemplateCountType({
        label: subscription.entitlement.upgrade_template_count?.type,
        value: subscription.entitlement.upgrade_template_count?.type,
      });
    } else {
      setUpgradeTemplateCountLimit(0);
      setUpgradeTemplateCountType({
        label: 'Hard',
        value: 'Hard',
      });
    }
  }, [subscription, props.visible]);

  const onConfirmClick = async () => {
    try {
      await updateSubscription(props.accountId, props.subscriptionId, {
        entitlement: {
          // FIXME: this is a hack to get around the fact that the API
          // requires node_count field to be present when updating
          node_count: subscription?.entitlement.node_count || {
            limit: 0,
            type: 'Hard',
          },
          upgrade_template_count: {
            limit: upgradeTemplateCountLimit,
            type: upgradeTemplateCountType.value as PlanUsageLimitType,
          },
        },
      });
      await mutate();
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      props.onDismiss();
    }
  };

  return (
    <ProcessingDialogModal
      header={`Update Upgrade Template Count`}
      onConfirm={onConfirmClick}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <Container>
        <ResolvedEntitlementAdjustor
          limit={upgradeTemplateCountLimit.toString()}
          limitOnChange={setUpgradeTemplateCountLimit}
          type={upgradeTemplateCountType}
          typeOnChange={setUpgradeTemplateCountType}
        />
      </Container>
    </ProcessingDialogModal>
  );
}

function ResolvedEntitlementAdjustor(props: {
  limit: string;
  limitOnChange: (value: number) => void;
  type: { label?: string; value?: string };
  typeOnChange: (value: { label?: string; value?: string }) => void;
}): JSX.Element {
  return (
    <ColumnLayout columns={2} variant="text-grid">
      <FormField label="Limit">
        <Input
          type="number"
          value={props.limit}
          onChange={({ detail }) => props.limitOnChange(parseInt(detail.value))}
        />
      </FormField>
      <FormField label="Type">
        <Select
          selectedOption={props.type}
          onChange={({ detail }) => props.typeOnChange(detail.selectedOption)}
          options={[
            { label: 'Hard', value: 'Hard' },
            { label: 'Soft', value: 'Soft' },
          ]}
        />
      </FormField>
    </ColumnLayout>
  );
}

function AccountSubscriptionClusterCountAdjustor(props: {
  accountId: string;
  subscriptionId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  setError?: (error: string) => void;
}): JSX.Element {
  const { data, mutate } = useListSubscriptions(props.accountId);
  const subscription = data?.data.find(
    (s) => s.subscription_id === props.subscriptionId,
  );

  const [clusterCountLimit, setClusterCountLimit] = useState(
    subscription?.entitlement.cluster_count?.limit || 0,
  );
  const [clusterCountType, setClusterCountType] = useState<{
    label?: string;
    value?: string;
  }>({
    label: subscription?.entitlement.cluster_count?.type || 'Hard',
    value: subscription?.entitlement.cluster_count?.type || 'Hard',
  });

  useEffect(() => {
    if (subscription?.entitlement?.cluster_count) {
      setClusterCountLimit(subscription.entitlement.cluster_count?.limit);
      setClusterCountType({
        label: subscription.entitlement.cluster_count?.type,
        value: subscription.entitlement.cluster_count?.type,
      });
    } else {
      setClusterCountLimit(0);
      setClusterCountType({
        label: 'Hard',
        value: 'Hard',
      });
    }
  }, [subscription, props.visible]);

  const onConfirmClick = async () => {
    try {
      await updateSubscription(props.accountId, props.subscriptionId, {
        entitlement: {
          // FIXME: this is a hack to get around the fact that the API
          // requires node_count field to be present when updating
          node_count: subscription?.entitlement.node_count || {
            limit: 0,
            type: 'Hard',
          },
          cluster_count: {
            limit: clusterCountLimit,
            type: clusterCountType.value as PlanUsageLimitType,
          },
        },
      });
      await mutate();
      props.onConfirm();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      props.onDismiss();
    }
  };

  return (
    <ProcessingDialogModal
      header={`Update Cluster Count`}
      onConfirm={onConfirmClick}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <Container>
        <ResolvedEntitlementAdjustor
          limit={clusterCountLimit.toString()}
          limitOnChange={setClusterCountLimit}
          type={clusterCountType}
          typeOnChange={setClusterCountType}
        />
      </Container>
    </ProcessingDialogModal>
  );
}

function flattenPages<T>(pages: { data: T[] }[] | undefined): T[] | undefined {
  if (!pages) return undefined;
  return pages.reduce<T[]>(
    (acc, page) => page?.data && acc.concat(...page.data),
    [],
  );
}

function AccountSubscriptionAddonEntitlementAdjustor(props: {
  accountId: string;
  subscriptionId: string;
  visible: boolean;
}): JSX.Element {
  const [
    editAddonEntitlementDialogVisible,
    setEditAddonEntitlementDialogVisible,
  ] = useState(false);
  const [searchAddonName, setSearchAddonName] = useState('');
  const [addonToEdit, setAddonToEdit] = useState<AddonEntitlement>();
  const [
    addEditAddonEntitlementProcessing,
    setAddEditAddonEntitlementProcessing,
  ] = useState(false);

  // API data
  const { data, mutate } = useListSubscriptions(props.accountId);
  const subscription = data?.data.find(
    (s) => s.subscription_id === props.subscriptionId,
  );

  // Table data
  const subscriptionAddons = subscription?.entitlement?.addons || [];
  const { data: chkkSupportedAddons } = useListAddonsInfinite();
  const chkkSupportedAddonsList: Addon[] | undefined =
    flattenPages(chkkSupportedAddons);
  const tableData = subscriptionAddons.map((addon) => {
    const addonName = chkkSupportedAddonsList
      ? chkkSupportedAddonsList.find((a) => a.id === addon.addon_id)?.name
      : addon.addon_id;
    return {
      ...addon,
      name: addonName as string,
    };
  });

  const onAddEditEntitlement = async (
    addonId: string,
    allowUpgrade: boolean,
    removeAddon?: boolean,
  ) => {
    setAddEditAddonEntitlementProcessing(true);
    try {
      // Remove the addon being edited (if already present)
      const updatedAddons = subscriptionAddons.filter(
        (a) => a.addon_id !== addonId,
      );

      // Add the updated or new addon entitlement
      !removeAddon &&
        updatedAddons.push({
          addon_id: addonId,
          individual_upgrade_allowed: allowUpgrade,
        });

      await updateSubscription(props.accountId, props.subscriptionId, {
        entitlement: {
          // FIXME: this is a hack to get around the fact that the API
          // requires node_count field to be present when updating
          node_count: subscription?.entitlement.node_count || {
            limit: 0,
            type: 'Hard',
          },
          addons: updatedAddons,
        },
      });
      await mutate();
      setEditAddonEntitlementDialogVisible(false);
      setAddEditAddonEntitlementProcessing(false);
    } catch (err: unknown) {
      console.log(err);
      setEditAddonEntitlementDialogVisible(false);
      setAddEditAddonEntitlementProcessing(false);
    }
  };

  return (
    <Container>
      <SpaceBetween size="m">
        <Header
          variant="h2"
          actions={
            <Button
              onClick={() => {
                setAddonToEdit(undefined);
                setEditAddonEntitlementDialogVisible(true);
              }}
            >
              Add Add-on Entitlement
            </Button>
          }
        >
          Add-on Entitlements
        </Header>
        <FormField
          label="Search"
          secondaryControl={
            searchAddonName && (
              <Button
                iconName="close"
                variant="icon"
                onClick={() => setSearchAddonName('')}
              />
            )
          }
        >
          <Input
            placeholder="Search Add-ons"
            value={searchAddonName}
            onChange={({ detail }) => setSearchAddonName(detail.value)}
          />
        </FormField>
        <Table
          columnDefinitions={[
            {
              id: 'name',
              header: 'Name',
              cell: (item) => item.name,
            },
            {
              id: 'id',
              header: 'Add-on ID',
              cell: (item) => item.addon_id,
            },
            {
              id: 'individual_upgrade_allow',
              header: 'Individual Upgrade Allowed',
              cell: (item) => (item.individual_upgrade_allowed ? 'Yes' : 'No'),
            },
            {
              id: 'actions',
              header: 'Actions',
              cell: (item) => (
                <Button
                  iconName="edit"
                  variant="icon"
                  ariaLabel="Edit Entitlement"
                  onClick={() => {
                    setEditAddonEntitlementDialogVisible(true);
                    setAddonToEdit(item);
                  }}
                />
              ),
            },
          ]}
          items={tableData.filter((a) =>
            a.name.toLowerCase().includes(searchAddonName.toLowerCase()),
          )}
        />
      </SpaceBetween>
      {editAddonEntitlementDialogVisible && (
        <AddEditAddonEntitlementDialog
          visible={editAddonEntitlementDialogVisible}
          onDismiss={() => setEditAddonEntitlementDialogVisible(false)}
          addonToEdit={addonToEdit}
          onConfirm={onAddEditEntitlement}
          confirmLoading={addEditAddonEntitlementProcessing}
          subscriptionAddons={subscriptionAddons}
          chkkAddons={chkkSupportedAddonsList?.sort((a, b) =>
            a.name.localeCompare(b.name),
          )}
        />
      )}
    </Container>
  );
}

const AddEditAddonEntitlementDialog = (props: {
  visible: boolean;
  addonToEdit?: AddonEntitlement;
  onDismiss: () => void;
  onConfirm: (
    addonId: string,
    allowUpgrade: boolean,
    removeAddon?: boolean,
  ) => Promise<void>;
  confirmLoading: boolean;
  subscriptionAddons: AddonEntitlement[];
  chkkAddons: Addon[] | undefined;
}) => {
  const [selectedAddon, setSelectedAddon] = useState<{
    id: string;
    name: string;
  }>();

  useEffect(() => {
    if (props.addonToEdit?.addon_id && !selectedAddon) {
      const matchingAddon = props.chkkAddons?.find(
        (a) => a.id === props.addonToEdit?.addon_id,
      );
      setSelectedAddon({
        id: matchingAddon?.id as string,
        name: matchingAddon?.name as string,
      });
    }
  }, [props.addonToEdit, props.chkkAddons?.length]);

  const [allowIndividualUpgrade, setAllowIndividualUpgrade] = useState(
    props.addonToEdit?.addon_id
      ? props.addonToEdit.individual_upgrade_allowed
      : false,
  );
  const addonToAddAlreadyExists =
    !props.addonToEdit?.addon_id &&
    props.subscriptionAddons.some((a) => a.addon_id === selectedAddon?.id);

  const resetValues = () => {
    setSelectedAddon(undefined);
    setAllowIndividualUpgrade(false);
  };
  return (
    <Modal
      visible={props.visible}
      onDismiss={() => {
        props.onDismiss();
        resetValues();
      }}
      header={
        props.addonToEdit?.addon_id
          ? 'Edit Add-on Entitlement'
          : 'Add Add-on Entitlement'
      }
      footer={
        <SpaceBetween direction="horizontal" size="s">
          {props.addonToEdit?.addon_id && (
            <Button
              onClick={async () => {
                await props.onConfirm(
                  selectedAddon?.id as string,
                  allowIndividualUpgrade,
                  true,
                );
                resetValues();
              }}
              variant="normal"
              loading={props.confirmLoading}
            >
              Remove Add-on Entitlement
            </Button>
          )}
          <Button
            onClick={async () => {
              if (!addonToAddAlreadyExists) {
                await props.onConfirm(
                  selectedAddon?.id as string,
                  allowIndividualUpgrade,
                );
                resetValues();
              }
            }}
            variant="primary"
            loading={props.confirmLoading}
          >
            {props.addonToEdit?.addon_id
              ? 'Confirm Changes'
              : 'Confirm Add-on Entitlement'}
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween size="m">
        <FormField
          label="Add-on"
          errorText={
            addonToAddAlreadyExists
              ? 'Add-on is already part of entitlement'
              : undefined
          }
        >
          <Select
            disabled={!!props.addonToEdit?.addon_id}
            selectedOption={{
              label: selectedAddon?.name,
              value: selectedAddon?.id || '',
            }}
            onChange={({ detail }) =>
              setSelectedAddon({
                id: detail.selectedOption.value || '',
                name: detail.selectedOption.label || '',
              })
            }
            options={
              props.chkkAddons?.map((addon) => {
                return {
                  label: addon.name,
                  value: addon.id,
                };
              }) || []
            }
            placeholder="Select Add-on"
          />
        </FormField>
        <FormField label="Allow Individual Upgrade">
          <Checkbox
            checked={allowIndividualUpgrade}
            onChange={({ detail }) => setAllowIndividualUpgrade(detail.checked)}
          >
            Allow Individual Upgrade Template for the Add-on (outside Cluster
            Upgrades)
          </Checkbox>
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

function AccountSubscriptionCreator(props: {
  accountId: string;
  onConfirm: () => void;
  onDismiss: () => void;
  setError?: (error: string) => void;
  visible: boolean;
}): JSX.Element {
  const [inputLock, setInputLock] = useState(true);
  const [plan, setPlan] = useState<{
    label?: string;
    value?: SubscriptionPlan;
  }>({});
  const [name, setName] = useState('');
  const [entitlement, setEntitlement] = useState<
    ResolvedEntitlement | undefined
  >(undefined);
  const currentDate = new Date();
  const [startDate, setStartDate] = useState<string | undefined>(
    format(currentDate, 'yyyy-MM-dd'),
  );
  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);
  const [revocationDate, setRevocationDate] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const name = getDefaultName(plan.value);
    const entitlement = getDefaultEntitlement(plan.value);
    name && setName(name);
    entitlement && setEntitlement(entitlement);
    plan.value && setInputLock(false);
  }, [plan]);

  const resetValues = () => {
    setPlan({});
    setInputLock(true);
    setEntitlement(undefined);
    setExpiryDate('');
    setStartDate('');
    setRevocationDate('');
  };

  const onConfirmClick = async () => {
    let expiry = 0;
    if (expiryDate) {
      const expiryDateObj = new Date(`${expiryDate}`);
      expiry = Math.ceil(expiryDateObj.getTime() / 1000);
    }
    let revocation = 0;
    if (revocationDate) {
      const revocationDateObj = new Date(`${revocationDate}`);
      revocation = Math.ceil(revocationDateObj.getTime() / 1000);
    }
    let started = 0;
    if (startDate) {
      const startDateObj = new Date(`${startDate}`);
      started = Math.ceil(startDateObj.getTime() / 1000);
    }
    try {
      await createSubscription(props.accountId, {
        plan: plan?.value as SubscriptionPlan,
        type: SubscriptionType.Internal,
        name: name,
        entitlement: entitlement as ResolvedEntitlement,
        expiry: expiry === 0 ? undefined : expiry,
        revocation: revocation === 0 ? undefined : revocation,
        started: started === 0 ? undefined : started,
      });
      props.onConfirm();
      resetValues();
    } catch (err: unknown) {
      props.setError?.(JSON.stringify(err));
      console.log(err);
      onDismissClick();
    }
  };

  const onDismissClick = () => {
    resetValues();
    props.onDismiss();
  };
  return (
    <ProcessingDialogModal
      header={`Create new Subscription`}
      onConfirm={onConfirmClick}
      onDismiss={onDismissClick}
      visible={props.visible}
    >
      <SpaceBetween direction="vertical" size="m">
        <Container>
          <SpaceBetween direction="vertical" size="l">
            <ColumnLayout columns={1}>
              <FormField label="Plan">
                <Select
                  selectedOption={plan}
                  onChange={({ detail }) =>
                    setPlan({
                      label: detail.selectedOption.label,
                      value: detail.selectedOption.value as SubscriptionPlan,
                    })
                  }
                  options={[
                    { label: 'Free Trial', value: 'FreeTrial' },
                    { label: 'Guided POC', value: 'GuidedPOC' },
                    { label: 'POC v2', value: 'POCv2' },
                    { label: 'Demo', value: 'Demo' },
                    { label: 'Starter', value: 'Starter' },
                    { label: 'Business', value: 'Business' },
                    { label: 'Enterprise', value: 'Enterprise' },
                  ]}
                />
              </FormField>
              <FormField
                label="Type"
                constraintText="Only Internal type is supported."
              >
                <Input disabled={true} value={'Internal'} />
              </FormField>
              <FormField label="Name">
                <Input
                  value={name}
                  onChange={({ detail }) => setName(detail.value)}
                  disabled={inputLock}
                  placeholder="Select Plan"
                />
              </FormField>
              <Header variant="h3">Entitlement</Header>
              <FormField label="Features">
                <SpaceBetween direction="horizontal" size="s">
                  <Checkbox
                    checked={
                      (entitlement?.features || []).includes('request_addon') ||
                      false
                    }
                    onChange={(event) => {
                      if (event.detail.checked) {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          features: (e?.features || []).concat('request_addon'),
                        }));
                      } else {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          features: (e?.features || []).filter(
                            (f) => f !== 'request_addon',
                          ),
                        }));
                      }
                    }}
                  >
                    Can request Addon
                  </Checkbox>
                  <Checkbox
                    checked={
                      (entitlement?.features || []).includes('request_cloud') ||
                      false
                    }
                    onChange={(event) => {
                      if (event.detail.checked) {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          features: (e?.features || []).concat('request_cloud'),
                        }));
                      } else {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          features: (e?.features || []).filter(
                            (f) => f !== 'request_cloud',
                          ),
                        }));
                      }
                    }}
                  >
                    Can request Cloud
                  </Checkbox>
                </SpaceBetween>
              </FormField>
              <FormField label="Node Count">
                <SpaceBetween direction="vertical" size="xs">
                  <Input
                    type="number"
                    value={entitlement?.node_count?.limit.toString() || ''}
                    disabled={inputLock}
                    placeholder={inputLock ? 'Select Plan' : 'None'}
                    onChange={({ detail }) => {
                      setEntitlement({
                        ...entitlement,
                        node_count: {
                          ...entitlement?.node_count,
                          limit: parseInt(detail.value),
                          type:
                            entitlement?.node_count?.type ||
                            ('Soft' as PlanUsageLimitType), // provide a default value of 'Hard' for type when it is undefined
                        },
                      });
                    }}
                  />
                  <Select
                    selectedOption={{
                      label: entitlement?.node_count?.type || '',
                      value: entitlement?.node_count?.type || '',
                    }}
                    options={[
                      {
                        label: 'Hard',
                        value: 'Hard',
                      },
                      {
                        label: 'Soft',
                        value: 'Soft',
                      },
                    ]}
                    onChange={({ detail }) => {
                      setEntitlement({
                        ...entitlement,
                        node_count: {
                          ...entitlement?.node_count,
                          type: detail.selectedOption
                            .value as PlanUsageLimitType,
                          limit: entitlement?.node_count?.limit || 0, // provide a default value of 0 for limit when it is undefined
                        },
                      });
                    }}
                    disabled={inputLock}
                  />
                </SpaceBetween>
              </FormField>
              <ColumnLayout columns={2}>
                <FormField label="Cluster Upgrade Template Count">
                  <SpaceBetween direction="vertical" size="xs">
                    <Input
                      type="number"
                      value={
                        entitlement?.upgrade_template_count?.limit.toString() ||
                        ''
                      }
                      disabled={inputLock}
                      placeholder={inputLock ? 'Select Plan' : 'None'}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          upgrade_template_count: {
                            ...entitlement?.upgrade_template_count,
                            limit: parseInt(detail.value),
                            type:
                              entitlement?.upgrade_template_count?.type ||
                              ('Hard' as PlanUsageLimitType), // provide a default value of 'Hard' for type when it is undefined
                          },
                        }));
                      }}
                    />
                    <Select
                      selectedOption={{
                        label: entitlement?.upgrade_template_count?.type || '',
                        value: entitlement?.upgrade_template_count?.type || '',
                      }}
                      options={[
                        {
                          label: 'Hard',
                          value: 'Hard',
                        },
                        {
                          label: 'Soft',
                          value: 'Soft',
                        },
                      ]}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          upgrade_template_count: {
                            ...entitlement?.upgrade_template_count,
                            type: detail.selectedOption
                              .value as PlanUsageLimitType,
                            limit:
                              entitlement?.upgrade_template_count?.limit || 0, // provide a default value of 0 for limit when it is undefined
                          },
                        }));
                      }}
                      disabled={inputLock}
                    />
                  </SpaceBetween>
                </FormField>
                <FormField label="Cluster Upgrade Plan Count">
                  <SpaceBetween direction="vertical" size="xs">
                    <Input
                      type="number"
                      value={
                        entitlement?.upgrade_plan_count?.limit.toString() || ''
                      }
                      disabled={inputLock}
                      placeholder={inputLock ? 'Select Plan' : 'None'}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          upgrade_plan_count: {
                            ...entitlement?.upgrade_plan_count,
                            limit: parseInt(detail.value),
                            type:
                              entitlement?.upgrade_plan_count?.type ||
                              ('Hard' as PlanUsageLimitType), // provide a default value of 'Hard' for type when it is undefined
                          },
                        }));
                      }}
                    />
                    <Select
                      selectedOption={{
                        label: entitlement?.upgrade_plan_count?.type || '',
                        value: entitlement?.upgrade_plan_count?.type || '',
                      }}
                      options={[
                        {
                          label: 'Hard',
                          value: 'Hard',
                        },
                        {
                          label: 'Soft',
                          value: 'Soft',
                        },
                      ]}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          upgrade_plan_count: {
                            ...entitlement?.upgrade_plan_count,
                            type: detail.selectedOption
                              .value as PlanUsageLimitType,
                            limit: entitlement?.upgrade_plan_count?.limit || 0, // provide a default value of 0 for limit when it is undefined
                          },
                        }));
                      }}
                      disabled={inputLock}
                    />
                  </SpaceBetween>
                </FormField>
              </ColumnLayout>
              <ColumnLayout columns={2}>
                <FormField label="Add-on Upgrade Templates Count">
                  <SpaceBetween direction="vertical" size="xs">
                    <Input
                      type="number"
                      value={
                        entitlement?.addon_upgrade_template_count?.limit.toString() ||
                        ''
                      }
                      disabled={inputLock}
                      placeholder={inputLock ? 'Select Plan' : 'None'}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          addon_upgrade_template_count: {
                            ...entitlement?.addon_upgrade_template_count,
                            limit: parseInt(detail.value),
                            type:
                              entitlement?.upgrade_template_count?.type ||
                              ('Hard' as PlanUsageLimitType), // provide a default value of 'Hard' for type when it is undefined
                          },
                        }));
                      }}
                    />
                    <Select
                      selectedOption={{
                        label:
                          entitlement?.addon_upgrade_template_count?.type || '',
                        value:
                          entitlement?.addon_upgrade_template_count?.type || '',
                      }}
                      options={[
                        {
                          label: 'Hard',
                          value: 'Hard',
                        },
                        {
                          label: 'Soft',
                          value: 'Soft',
                        },
                      ]}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          addon_upgrade_template_count: {
                            ...entitlement?.addon_upgrade_template_count,
                            type: detail.selectedOption
                              .value as PlanUsageLimitType,
                            limit:
                              entitlement?.addon_upgrade_template_count
                                ?.limit || 0, // provide a default value of 0 for limit when it is undefined
                          },
                        }));
                      }}
                      disabled={inputLock}
                    />
                  </SpaceBetween>
                </FormField>
                <FormField label="Add-on Upgrade Plan Count">
                  <SpaceBetween direction="vertical" size="xs">
                    <Input
                      type="number"
                      value={
                        entitlement?.addon_upgrade_plan_count?.limit.toString() ||
                        ''
                      }
                      disabled={inputLock}
                      placeholder={inputLock ? 'Select Plan' : 'None'}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          addon_upgrade_plan_count: {
                            ...entitlement?.addon_upgrade_plan_count,
                            limit: parseInt(detail.value),
                            type:
                              entitlement?.addon_upgrade_plan_count?.type ||
                              ('Hard' as PlanUsageLimitType), // provide a default value of 'Hard' for type when it is undefined
                          },
                        }));
                      }}
                    />
                    <Select
                      selectedOption={{
                        label:
                          entitlement?.addon_upgrade_plan_count?.type || '',
                        value:
                          entitlement?.addon_upgrade_plan_count?.type || '',
                      }}
                      options={[
                        {
                          label: 'Hard',
                          value: 'Hard',
                        },
                        {
                          label: 'Soft',
                          value: 'Soft',
                        },
                      ]}
                      onChange={({ detail }) => {
                        setEntitlement((e) => ({
                          ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                          addon_upgrade_plan_count: {
                            ...entitlement?.addon_upgrade_plan_count,
                            type: detail.selectedOption
                              .value as PlanUsageLimitType,
                            limit:
                              entitlement?.addon_upgrade_plan_count?.limit || 0, // provide a default value of 0 for limit when it is undefined
                          },
                        }));
                      }}
                      disabled={inputLock}
                    />
                  </SpaceBetween>
                </FormField>
              </ColumnLayout>
              <FormField label="Cluster Count">
                <SpaceBetween direction="vertical" size="xs">
                  <Input
                    type="number"
                    value={entitlement?.cluster_count?.limit.toString() || ''}
                    disabled={inputLock}
                    placeholder={inputLock ? 'Select Plan' : 'None'}
                    onChange={({ detail }) => {
                      setEntitlement((e) => ({
                        ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                        cluster_count: {
                          ...entitlement?.cluster_count,
                          limit: parseInt(detail.value),
                          type:
                            entitlement?.cluster_count?.type ||
                            ('Hard' as PlanUsageLimitType), // provide a default value of 'Hard' for type when it is undefined
                        },
                      }));
                    }}
                  />
                  <Select
                    selectedOption={{
                      label: entitlement?.cluster_count?.type || '',
                      value: entitlement?.cluster_count?.type || '',
                    }}
                    options={[
                      {
                        label: 'Hard',
                        value: 'Hard',
                      },
                      {
                        label: 'Soft',
                        value: 'Soft',
                      },
                    ]}
                    onChange={({ detail }) => {
                      setEntitlement((e) => ({
                        ...(e || { node_count: { limit: 0, type: 'Hard' } }),
                        cluster_count: {
                          ...entitlement?.cluster_count,
                          type: detail.selectedOption
                            .value as PlanUsageLimitType,
                          limit: entitlement?.cluster_count?.limit || 0, // provide a default value of 0 for limit when it is undefined
                        },
                      }));
                    }}
                    disabled={inputLock}
                  />
                </SpaceBetween>
              </FormField>
            </ColumnLayout>
            <Header variant="h3">Subscription Dates</Header>
            <ColumnLayout columns={3}>
              <FormField
                label="Started"
                constraintText="Use YYYY/MM/DD format."
              >
                <DatePicker
                  disabled={inputLock}
                  value={startDate || ''}
                  onChange={(event) => setStartDate(event.detail.value)}
                  placeholder="None"
                />
              </FormField>
              <FormField label="Expiry" constraintText="Use YYYY/MM/DD format.">
                <DatePicker
                  disabled={inputLock}
                  value={expiryDate || ''}
                  onChange={(event) => setExpiryDate(event.detail.value)}
                  placeholder="None"
                />
              </FormField>
              <FormField
                label="Revocation"
                constraintText="Use YYYY/MM/DD format."
              >
                <DatePicker
                  disabled={inputLock}
                  value={revocationDate || ''}
                  onChange={(event) => setRevocationDate(event.detail.value)}
                  placeholder="None"
                />
              </FormField>
            </ColumnLayout>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </ProcessingDialogModal>
  );
}

function getDefaultName(
  plan: SubscriptionPlan | undefined,
): string | undefined {
  switch (plan) {
    case 'FreeTrial':
      return 'Free Trial';
    case 'GuidedPOC':
      return 'Guided POC';
    case 'Demo':
      return 'Demo';
    case 'Starter':
      return 'Starter';
    case 'Business':
      return 'Business';
    case 'Enterprise':
      return 'Enterprise';
    case 'POCv2':
      return 'POCv2';
  }
}

function getDefaultEntitlement(
  plan: SubscriptionPlan | undefined,
): ResolvedEntitlement | undefined {
  switch (plan) {
    case 'FreeTrial':
      return {
        node_count: {
          limit: 20,
          type: 'Soft',
        },
        features: ['request_addon', 'request_cloud'],
      };
    case 'GuidedPOC':
      return {
        node_count: {
          limit: 100,
          type: 'Soft',
        },
        upgrade_plan_count: {
          limit: 1,
          type: 'Soft',
        },
        upgrade_template_count: {
          limit: 1,
          type: 'Soft',
        },
        features: ['request_addon', 'request_cloud'],
      };
    case 'Demo':
      return {
        node_count: {
          limit: 0,
          type: 'Hard',
        },
      };
    case 'Starter':
      return {
        node_count: {
          limit: 100,
          type: 'Soft',
        },
        upgrade_plan_count: {
          limit: 0,
          type: 'Hard',
        },
        upgrade_template_count: {
          limit: 0,
          type: 'Hard',
        },
        features: ['request_addon', 'request_cloud'],
      };
    case 'Business':
      return {
        node_count: {
          limit: 100,
          type: 'Soft',
        },
        upgrade_plan_count: {
          limit: 0,
          type: 'Soft',
        },
        upgrade_template_count: {
          limit: 0,
          type: 'Soft',
        },
        features: ['request_addon', 'request_cloud'],
      };
    case 'Enterprise':
      return {
        node_count: {
          limit: 500,
          type: 'Soft',
        },
        upgrade_plan_count: {
          limit: 50,
          type: 'Soft',
        },
        upgrade_template_count: {
          limit: 10,
          type: 'Soft',
        },
        features: ['request_addon', 'request_cloud'],
      };
    case 'POCv2':
      return {
        node_count: {
          limit: 500,
          type: PlanUsageLimitType.Soft,
        },
        upgrade_plan_count: {
          limit: 1,
          type: 'Soft',
        },
        addon_upgrade_plan_count: {
          limit: 1,
          type: 'Soft',
        },
        upgrade_template_count: {
          limit: 1,
          type: 'Soft',
        },
        addon_upgrade_template_count: {
          limit: 1,
          type: 'Soft',
        },
        cluster_count: {
          limit: 1,
          type: 'Soft',
        },
        addons: [
          {
            addon_id: 'addon_404d077e-3259-417b-9ce8-04aa1459fa16',
            individual_upgrade_allowed: false,
            // addon_slug: 'amazon-vpc-cni',
          },
          {
            addon_id: 'addon_b692878c-4298-4f61-8100-df19363b0ed6',
            individual_upgrade_allowed: false,
            // addon_slug: 'core-dns',
          },
          {
            addon_id: 'addon_d7191da5-c5f3-45f7-a620-38d434dd5096',
            individual_upgrade_allowed: false,
            // addon_slug: 'cert-manager',
          },
          {
            addon_id: 'addon_143085db-4ed1-46da-ad99-0e79868d44ca',
            individual_upgrade_allowed: false,
            // addon_slug: 'kube-proxy',
          },
          {
            addon_id: 'addon_30e39ed1-5e82-4806-af53-92866f4b25e0',
            individual_upgrade_allowed: false,
            // addon_slug: 'argo-cd',
          },
          {
            addon_id: 'addon_dda0b12f-9a76-43ff-a024-9839ed3ee57b',
            individual_upgrade_allowed: false,
            // addon_slug: 'argo-workflows',
          },
          {
            addon_id: 'addon_fd1b3544-c921-4564-82b2-7caf7dba3f09',
            individual_upgrade_allowed: false,
            // addon_slug: 'argo-rollouts',
          },
          {
            addon_id: 'addon_f8dbd4eb-4dc8-44f1-910b-86d6c64ff46b',
            individual_upgrade_allowed: false,
            // addon_slug: 'kube-state-metrics',
          },
          {
            addon_id: 'addon_0f9f596d-9bf6-40ed-b95e-a21ab4d3dd58',
            individual_upgrade_allowed: false,
            // addon_slug: 'external-secrets-operator',
          },
          {
            addon_id: 'addon_767ffeae-6c39-4cf9-bd1f-0ceaf9f32760',
            individual_upgrade_allowed: false,
            // addon_slug: 'kubernetes-metrics-server',
          },
          {
            addon_id: 'addon_29cf33a4-4c53-445d-a81e-ec71308cfd3e',
            individual_upgrade_allowed: false,
            // addon_slug: 'kubernetes-dashboard',
          },
          {
            addon_id: 'addon_7e42e224-b8dc-4bf9-8efc-7c597bd2108b',
            individual_upgrade_allowed: false,
            // addon_slug: 'vertical-pod-autoscaler',
          },
          {
            addon_id: 'addon_5888c383-2d4e-4fe4-ab83-292587895e2b',
            individual_upgrade_allowed: false,
            // addon_slug: 'datadog-agent',
          },
          {
            addon_id: 'addon_13f538f7-10f3-4c5e-82a1-488b98401e60',
            individual_upgrade_allowed: false,
            // addon_slug: 'cluster-autoscaler',
          },
          {
            addon_id: 'addon_89549157-9fbe-4297-90f2-11122c700aee',
            individual_upgrade_allowed: false,
            // addon_slug: 'grafana',
          },
          {
            addon_id: 'addon_7f1e198a-c2e0-446b-8567-f7154dfb38de',
            individual_upgrade_allowed: false,
            // addon_slug: 'prometheus',
          },
          {
            addon_id: 'addon_d6e318d9-5802-46a4-a497-a6ffa7f8c57f',
            individual_upgrade_allowed: false,
            // addon_slug: 'prometheus-alertmanager',
          },
          {
            addon_id: 'addon_6412368a-680d-47f7-82a0-0930561ddd36',
            individual_upgrade_allowed: false,
            // addon_slug: 'fluent-bit',
          },
          {
            addon_id: 'addon_304813ed-5423-4a5e-8783-49c7af10572e',
            individual_upgrade_allowed: false,
            // addon_slug: 'keda',
          },
          {
            addon_id: 'addon_5b72ff12-b76a-46fe-b7c6-e6a8f5982c51',
            individual_upgrade_allowed: false,
            // addon_slug: 'ingress-nginx-controller-community',
          },
          {
            addon_id: 'addon_e79277b6-3549-428f-b3c8-08cabe46c878',
            individual_upgrade_allowed: false,
            // addon_slug: 'vault-secrets-operator',
          },
          {
            addon_id: 'addon_cbdaa82d-3360-4bc7-a2b6-0978d11e96d4',
            // addon_slug: 'istio',
            individual_upgrade_allowed: true,
          },
          {
            addon_id: 'addon_cfadf3fa-c86d-4ce6-9035-9abe07c963ca',
            // addon_slug: 'hashicorp-consul-oss',
            individual_upgrade_allowed: true,
          },
          {
            addon_id: 'addon_6f43ce4d-ad7f-4563-b9f9-525b12976647',
            // addon_slug: 'gloo-edge-oss',
            individual_upgrade_allowed: true,
          },
          {
            addon_id: 'addon_f7e7522e-8a42-4da0-acc3-de076445f5d1',
            // addon_slug: 'contour',
            individual_upgrade_allowed: true,
          },
          {
            addon_id: 'addon_fdd78e80-073e-445f-96cd-bdd4fa94a6f3',
            // addon_slug: 'cilium',
            individual_upgrade_allowed: true,
          },
        ],
        features: [
          'request_addon',
          'request_cloud',
          'request_upgrade_plan',
          'request_addon_upgrade_plan',
          'request_upgrade_template',
          'request_addon_upgrade_template',
          'add_cluster',
          'add_cloud_connection',
        ],
      };
  }
}
