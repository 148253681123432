import {
  getGlobalListUpgradesKey,
  getGlobalListUpgradeTemplatesKey,
  getListOrganizationsKey,
  globalListUpgradeTemplates,
  globalListUpgrades,
  listOrganizations,
  listAddons,
  getListAddonsKey,
} from './admin';
import {
  GlobalListUpgradeTemplatesParams,
  GlobalListUpgradesParams,
  ListAddonsParams,
  ListOrganizationsParams,
} from './admin-models';
import { Key, SWRConfiguration } from 'swr';
import useSwrInfinite from 'swr/infinite';
import { customAdminApiInstance } from './custom-instance';

export const useGlobalListUpgradesInfinite = <TError = unknown>(
  params?: GlobalListUpgradesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof globalListUpgrades>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<ReturnType<typeof globalListUpgrades>> | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null) return getGlobalListUpgradesKey(params);

    // add the cursor to the API endpoint
    return getGlobalListUpgradesKey({
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };

  const swrFn = (args: readonly [string, GlobalListUpgradesParams]) =>
    globalListUpgrades(args[1]);

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    {
      revalidateFirstPage: false,
      persistSize: true,
      initialSize: 100000,
    },
  );

  return {
    swrKey,
    ...query,
  };
};

export const useListOrganizationsInfinite = <TError = unknown>(
  params?: ListOrganizationsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listOrganizations>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<ReturnType<typeof listOrganizations>> | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null) return getListOrganizationsKey(params);

    // add the cursor to the API endpoint
    return getListOrganizationsKey({
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };
  const swrFn = (args: [string, ListOrganizationsParams]) =>
    listOrganizations({ page_size: 500, ...args[1] });

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    {
      revalidateFirstPage: false,
      persistSize: true,
      initialSize: 100000,
    },
  );

  return {
    swrKey,
    ...query,
  };
};

export const useGlobalListUpgradeTemplatesInfinite = <TError = unknown>(
  params?: GlobalListUpgradeTemplatesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof globalListUpgradeTemplates>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<
      ReturnType<typeof globalListUpgradeTemplates>
    > | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null)
      return getGlobalListUpgradeTemplatesKey(params);

    // add the cursor to the API endpoint
    return getGlobalListUpgradeTemplatesKey({
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };

  const swrFn = (args: readonly [string, GlobalListUpgradeTemplatesParams]) =>
    globalListUpgradeTemplates(args[1]);

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    {
      revalidateFirstPage: false,
      persistSize: true,
      initialSize: 100000,
    },
  );

  return {
    swrKey,
    ...query,
  };
};
// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


export const useListAddonsInfinite = <TError = unknown>(
  params?: ListAddonsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listAddons>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customAdminApiInstance>;
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<ReturnType<typeof listAddons>> | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null) return getListAddonsKey(params);

    // add the cursor to the API endpoint
    return getListAddonsKey({
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };

  const swrFn = (args: readonly [string, ListAddonsParams]) => {
    return listAddons(args[1]);
  };

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    {
      revalidateFirstPage: false,
      persistSize: true,
      initialSize: 100000,
    },
  );

  return {
    swrKey,
    ...query,
  };
};
